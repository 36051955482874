import React from "react";
import { graphql } from "gatsby";
import Container from "../../components/container";
import Header from "../../components/header";
import MoreStories from "../../components/more-stories";
import PostBody from "../../components/post-body";
import PostHeader from "../../components/post-header";
import SectionSeparator from "../../components/section-separator";
import { HelmetDatoCms } from "gatsby-source-datocms";
import { Helmet } from "react-helmet";
import '../../styles/post.scss'
import Footer from "../../components/footer";
export default function Article({ data: { site, article, moreArticles } }) {
  return (
    <Container>
      <Helmet>
        {/* <script src="https://cdn.tailwindcss.com"></script> */}

      </Helmet>

      <HelmetDatoCms seo={article.seo} favicon={site.favicon} />
      <Header />
      <article className="article-blog">
        <PostHeader
          title={article.title}
          coverImage={article.coverImage}
          date={article.date}
          author={article.author}
        />
        <PostBody content={article.content} />
      </article>
      <SectionSeparator />
      {moreArticles.nodes.length > 0 && <MoreStories posts={moreArticles.nodes} />}
      <Footer />
    </Container>
  );
}

export const query = graphql`
  query ArticleBySlug($id: String) {
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    article: datoCmsArticle(id: { eq: $id }) {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      slug
      content {
        value
        blocks {
          __typename
          id: originalId
          image {
            gatsbyImageData(width: 700)
          }
        }
      }
      date
      coverImage {
        gatsbyImageData(width: 1500)
      }
      author {
        name
        picture {
          gatsbyImageData(
            layout: FIXED
            width: 48
            height: 48
            imgixParams: { sat: -100 }
          )
        }
      }
    }
    moreArticles: allDatoCmsArticle(
      sort: { fields: date, order: DESC }
      limit: 2
      filter: { id: { ne: $id } }
    ) {
      nodes {
        title
        slug
        excerpt
        date
        coverImage {
          small: gatsbyImageData(width: 760)
        }
        author {
          name
          picture {
            gatsbyImageData(
              layout: FIXED
              width: 48
              height: 48
              imgixParams: { sat: -100 }
            )
          }
        }
      }
    }
  }
`;
